<template>
  <div>
    <div class="moreInfo">
      <el-form :model="content" ref="form" label-width="80px" size="normal">
        <el-form-item label="收件人">
          {{ email }}
        </el-form-item>
        <el-form-item label="抄送" v-if="content.cc">
          {{ content.cc.join(',') }}
        </el-form-item>
        <el-form-item label="发件人">
          {{ userInfo.email }}
        </el-form-item>
        <el-form-item label="主题">
          <el-input
            v-model="subjectStr"
            placeholder="请输入主题"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <el-alert
        title="批量发送仅支持预览"
        type="warning"
        style="line-height: 1; margin: 10px 0 0"
      >
      </el-alert>

      <el-table :data="currentFile" style="width: 100%" size="mini">
        <el-table-column prop="name" label="当前邮件模板有如下附件">
        </el-table-column>
      </el-table>

      <!-- <p><label for="">收件人:</label> {{ email }}</p>
      <p v-if="content.cc">
        <label for="">抄送:</label> {{ content.cc.join(',') }}
      </p>
      <p><label for="">发件人:</label> {{ userInfo.email }}</p>
      <p>
        <label for="">主题:</label>
        <el-input
          v-model="content.subject"
          placeholder="请输入主题"
          size="normal"
          clearable
        ></el-input>
      </p> -->
    </div>
    <div class="inner" v-html="data" :contenteditable="!isMulti"></div>
    <div class="file" v-if="fileName">
      附件： {{ fileName }}
      <el-link
        type="primary"
        target="_blank"
        :href="'/file/download?key=' + this.content.file"
        :underline="false"
        style="margin: 0 0 0 15px"
      >
        点击下载
      </el-link>
    </div>
  </div>
</template>

<script>
const ejs = require('ejs')
import { mapState } from 'vuex'
import { stateList } from '../store/common'
// import temp from './temp'
export default {
  name: 'Uemail',
  props: {
    content: Object, // 正文内容
    templateList: Array, // 邮件模板列表
    scene: Number, // 模板场景
    subject: String,
    isMulti: Boolean, // 是否批量发送
  },
  watch: {
    'currentTemplate.subject': {
      immediate: true,
      handler(val) {
        let city = ''
        if (this.talent && this.talent.address) {
          const address = this.talent.address.split(' ')
          if (address[2]) {
            city = '-' + address[2]
          }
        }
        let renderContent = {
          recommendUser: this.recommendUser,
          post: this.post,
          talent: this.talent,
          contact: this.contact,
          company: this.company,
          viewTime: this.viewTime,
          viewType: this.viewType,
          userInfo: this.userInfo,
          viewInfo: this.viewInfo,
          now: this.now,
          content: this.content,
          city,
        }
        this.subjectStr = ejs.render(val, renderContent)
      },
    },

    subjectStr: {
      immediate: true,
      handler(val) {
        console.log('val', val)
        this.$emit('update:subject', val)
      },
    },
  },
  computed: {
    ...mapState(stateList),
    currentFile() {
      if (this.currentTemplate && this.currentTemplate.file) {
        return this.currentTemplate.file.split('||').map((n) => {
          return {
            name: n,
          }
        })
      }
      return []
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    currentTemplate() {
      return this.templateList.find((n, i) => {
        return this.content.emailTemplate
          ? n.id === this.content.emailTemplate
          : i === 0
      })
    },
    email() {
      return this.scene === 686
        ? this.content.email
        : this.scene === 687
        ? this.content.viewEmail
        : this.content.email
    },
    fileName() {
      if (this.content && this.content.file) {
        return this.content.file.split('/')[1]
      }
      return ''
    },
    recommendUser() {
      return this.content && this.content.recommendUser
        ? this.content.recommendUser
        : {}
    },
    post() {
      return this.content && this.content.post ? this.content.post : {}
    },
    talent() {
      // 如果是批量,取第一个
      let talent =
        this.content.talents && this.content.talents.length
          ? this.content.talents[0]
          : this.content.talent
          ? this.content.talent
          : {}

      return this.content ? talent : {}
    },
    contact() {
      return this.content && this.content.contact ? this.content.contact : {}
    },
    company() {
      return this.content && this.content.company ? this.content.company : {}
    },
    viewInfo() {
      return this.content && this.content.viewInfo
        ? this.longTextFormat(this.content.viewInfo)
        : ''
    },
    viewType() {
      if (this.content.viewType) {
        let ret = this.enumOptions.interviewType.find(
          (n) => n.value === this.content.viewType
        )
        return ret ? ret.label : ''
      }
      return ''
    },
    viewTime() {
      return this.content.viewTime
        ? this.$dayjs(this.content.viewTime).format('YYYY年MM月DD日 HH:mm')
        : ''
    },
  },
  data() {
    return {
      now: this.$dayjs().format('YYYY年MM月DD日'),
      str: '<%= people.join(", "); %>',
      data: '',
      subjectStr: '',
    }
  },
  methods: {
    longTextFormat(text) {
      let outText = text.replace(/[\n\r]/g, '<br>')
      return outText
    },
    async formatTemplate() {
      let keys = [
        {
          label: '学历及工作背景',
          key: 'backIntro',
        },
        {
          label: '能力匹配',
          key: 'skill',
        },
        {
          label: '优势劣势及软性评估',
          key: 'assess',
        },
        {
          label: '求职动机及到岗时间',
          key: 'intention',
        },
        {
          label: '薪资详情及期望',
          key: 'expectation',
        },
        {
          label: '其他补充内容',
          key: 'otherContent',
        },
      ]

      let content = { ...this.content }
      keys.forEach((obj) => {
        let { key } = obj
        if (content[key]) {
          content[key] = this.longTextFormat(content[key])
        }
      })

      // this.subjectStr = this.currentTemplate.subject

      let renderContent = {
        recommendUser: this.recommendUser,
        post: this.post,
        talent: this.talent,
        contact: this.contact,
        company: this.company,
        viewTime: this.viewTime,
        viewType: this.viewType,
        userInfo: this.userInfo,
        viewInfo: this.viewInfo,
        now: this.now,
        content,
        keys,
      }
      console.log('renderContent', renderContent)

      this.data = await ejs.render(this.currentTemplate.content, renderContent)
    },
  },
  mounted() {
    this.formatTemplate()
  },
}
</script>

<style lang="less">
.file {
  padding: 16px;
}
.moreInfo {
  line-height: 36px;
  padding: 0 16px;

  label {
    display: inline-block;
    width: 76px;
  }

  .el-form-item {
    margin: 0;
  }
}
</style>
